import React from 'react';
import type {
  WAbsoluteThemeComponentAssetView,
  WLengthView,
  WThemeComponentView,
} from '@zola/svc-web-api-ts-client';
import type { CroppedAbsoluteAssetDisplayType } from 'components/publicWebsiteV2/util/useCroppedBottomAbsoluteAssets';
import { appendWidthParam } from 'components/publicWebsiteV2/util/getRelativeImgSize';
import { Device } from 'lib/universal/utils/devices';
import { PageAnimationWrapper } from 'components/publicWebsiteV2/common/PageAnimationWrapper/PageAnimationWrapper';
import { mapAbsoluteAssetViewToStyledImg, mapInFlowAssetViewToStyledImg } from './mappers';

type RenderAbsoluteAssetsParams = {
  a?: WAbsoluteThemeComponentAssetView[];
  elementsRef?: React.MutableRefObject<React.RefObject<HTMLImageElement>[]>;
  croppedDimensions?: CroppedAbsoluteAssetDisplayType[];
  containerWidth?: number;
  device?: Device;
  pageType?: string;
};

export const renderAbsoluteAssets = ({
  a,
  elementsRef,
  croppedDimensions,
  containerWidth,
  device,
  pageType,
}: RenderAbsoluteAssetsParams): (JSX.Element | null)[] | null => {
  if (a) {
    if (a.length > 0) {
      return a.map((abs: WAbsoluteThemeComponentAssetView, i: number) => {
        const { style, image_url } = abs || {};
        if (!image_url || !style?.responsive_size) return null;
        let imageUrlWithParams;

        if (containerWidth && style) {
          // Mimic default CSS styling rule where assets absolutely positioned
          // with no explicit width set assume 100% relative to container
          const adjustedStyle = Object.assign({}, style);
          if (
            adjustedStyle?.responsive_size?.desktop &&
            !adjustedStyle?.responsive_size?.desktop?.width
          ) {
            adjustedStyle.responsive_size.desktop.width = {
              unit: ('PERCENTAGE' as unknown) as WLengthView.UnitEnum,
              value: 100,
            };
          }

          imageUrlWithParams = appendWidthParam({
            src: image_url,
            style: adjustedStyle,
            width: containerWidth,
            device,
          });
        }

        const Img = mapAbsoluteAssetViewToStyledImg(style?.responsive_size, croppedDimensions?.[i]);

        const hidePageAnimation = !!pageType && pageType !== 'home';

        return (
          <PageAnimationWrapper
            animationProps={{
              isAbsoluteImage: true,
              direction: { wipeDirection: 'down', panDirection: 'left' },
            }}
            animationNameOverride="page-animation-fade"
            hideAnimation={hidePageAnimation}
            key={`asset-${i}`}
            showOnPageLoad
          >
            <Img
              src={imageUrlWithParams || image_url}
              alt={style?.alt_text}
              ref={elementsRef?.current[i]}
            />
          </PageAnimationWrapper>
        );
      });
    }
  }
  return null;
};

export const renderInFlowAsset = (
  inFlowAssetsByLayout: WThemeComponentView['in_flow_assets_by_layout'],
  assetPlacement: 'before' | 'after',
  layout = 'MULTI_PAGE'
) => {
  const inFlowAsset = inFlowAssetsByLayout?.[layout]?.[assetPlacement];
  const Img = mapInFlowAssetViewToStyledImg(assetPlacement, inFlowAsset);
  return inFlowAsset ? <Img alt="" src={inFlowAsset.image_url} /> : <></>;
};
