import { createContext, useContext } from 'react';
import { PageAnimationTypes } from '@zola/zola-ui/src/animations/PageAnimations/types';
import { FoilAnimationColors } from '@zola/zola-ui/src/animations/TextFoil/TextFoil';
import type {
  WSkuPreview,
  OverlayEffectStyleCustomizationRequest,
  WWebsiteEffectView,
} from '@zola/svc-web-api-ts-client';

export type EasingFunction = 'ease-in' | 'linear' | 'ease-out' | 'ease-in-out';

type AnimationContextType = {
  // should we show any info related to animation on edit website, ie is the user in the experiment
  showAnimationsInEditWebsite: boolean;
  // does the user's account have animations enabled (either they are in the free group or they paid for animations)
  animationsEnabledForAccount: boolean;
  enableAnimationsAfterPurchase: () => void;
  appliedAnimations: WWebsiteEffectView[];
  availableAnimations: WWebsiteEffectView[];
  setAppliedAnimations?: (animations: WWebsiteEffectView[]) => void;
  foilAnimation?: FoilAnimationColors;
  experimentVariation?: string;
  animationProduct: Pick<WSkuPreview, 'price_cents' | 'id'>;
  pageAnimation?: PageAnimationTypes;
  overlayAnimation?: WWebsiteEffectView;
  hideHeroTitleText?: boolean;
  forcePageAnimationUpdate?: () => void;
  pageAnimationId?: number;
  overlayAnimationId?: number;
  isMobileDevice?: boolean;
  updateAppliedAnimations: (animation: WWebsiteEffectView, isSelected: boolean) => void;
  updateOverlayAnimationCustomizations: (
    overlayCustomization: OverlayEffectStyleCustomizationRequest
  ) => Promise<void | null>;
  isEditWebsite?: boolean;
  showFoilAnimation?: boolean;

  // debugging props - will be removed when animations are finalized
  showDebug?: boolean;
  setShowDebug?: (debug: boolean) => void;
  speed?: number;
  speedHero?: number;
  speedNav?: number;
  setSpeed?: (speed: number) => void;
  setSpeedHero?: (speed: number) => void;
  setSpeedNav?: (speed: number) => void;
  startScaleAt?: number;
  startScaleAtHero?: number;
  startScaleAtNav?: number;
  setStartScaleAt?: (scale: number) => void;
  setStartScaleAtHero?: (scale: number) => void;
  setStartScaleAtNav?: (scale: number) => void;
  translateYPercent?: number;
  translateYPercentHero?: number;
  translateYPercentNav?: number;
  setTranslateYPercent?: (percent: number) => void;
  setTranslateYPercentNav?: (percent: number) => void;
  setTranslateYPercentHero?: (percent: number) => void;
  easingFunction?: EasingFunction;
  easingFunctionHero?: EasingFunction;
  easingFunctionNav?: EasingFunction;
  setEasingFunction?: (func: EasingFunction) => void;
  setEasingFunctionHero?: (func: EasingFunction) => void;
  setEasingFunctionNav?: (func: EasingFunction) => void;
  translateXPercent?: number;
  translateXPercentHero?: number;
  translateXPercentNav?: number;

  setTranslateXPercent?: (percent: number) => void;
  setTranslateXPercentHero?: (percent: number) => void;
  setTranslateXPercentNav?: (percent: number) => void;
};

export const defaultAnimationContextValues: AnimationContextType = {
  showAnimationsInEditWebsite: false,
  animationsEnabledForAccount: false,
  enableAnimationsAfterPurchase: () => null,
  appliedAnimations: [],
  availableAnimations: [],
  setAppliedAnimations: () => null,
  experimentVariation: undefined,
  animationProduct: {} as WSkuPreview,
  foilAnimation: undefined,
  pageAnimation: undefined,
  showFoilAnimation: false,
  updateAppliedAnimations: () => null,
  updateOverlayAnimationCustomizations: () => Promise.resolve(),
  isMobileDevice: undefined,
  speed: 0,
  setSpeed: () => null,
  startScaleAt: 0,
  setStartScaleAt: () => null,
  showDebug: false,
  setShowDebug: () => null,
  forcePageAnimationUpdate: () => null,
  pageAnimationId: undefined,
  overlayAnimationId: undefined,
  translateYPercent: 0,
  translateYPercentHero: 0,
  translateYPercentNav: 0,
  setTranslateYPercent: () => null,
  setTranslateYPercentNav: () => null,
  setTranslateYPercentHero: () => null,
  easingFunction: undefined,
  setEasingFunction: () => null,
  hideHeroTitleText: undefined,
  overlayAnimation: undefined,
  isEditWebsite: false,
  translateXPercent: undefined,
  setTranslateXPercent: () => null,
  translateXPercentHero: undefined,
  translateXPercentNav: undefined,

  setTranslateXPercentHero: () => null,
  setTranslateXPercentNav: () => null,

  startScaleAtHero: undefined,
  startScaleAtNav: undefined,
  setStartScaleAtHero: () => null,
  setStartScaleAtNav: () => null,
  speedHero: undefined,
  speedNav: undefined,
  setSpeedHero: () => null,
  setSpeedNav: () => null,
};

export const AnimationContext = createContext<AnimationContextType>(defaultAnimationContextValues);

export const useAnimationContext = () => {
  const context = useContext(AnimationContext);
  if (context === undefined) {
    throw new Error('useAnimationContext must be used within an AnimationProvider');
  }
  return context;
};
