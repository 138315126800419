import { useAnimationContext } from 'components/manage/EditWebsite/EditWebsiteAddEffects/components/AnimationContext/AnimationContext';
import React, { Fragment } from 'react';
import {
  PageAnimation,
  PageAnimationOuterWrapper,
} from '@zola/zola-ui/src/animations/PageAnimations/PageAnimation/PageAnimation';
import {
  AnimationProps,
  PageAnimationTypes,
} from '@zola/zola-ui/src/animations/PageAnimations/types';
import styled from '@emotion/styled';
import { css } from '@emotion/react';

const StyledDiv = styled.div<{
  offsetPadding?: boolean;
  fullWidth?: boolean;
  fullHeight?: boolean;
}>`
  // fix bug where ascenders/descenders are getting cut off due to parent element's <1 line height
  // add padding to ensure the wipe animation covers the ascenders, offset by margin of the same amount to prevent layout change
  ${props =>
    props.offsetPadding &&
    css`
      ${PageAnimationOuterWrapper} {
        margin: -15px 0;
        > div {
          padding: 15px 0;
        }
      }
    `}

  ${props =>
    props.fullWidth &&
    css`
      width: 100%;
    `}

    ${props =>
      props.fullHeight &&
      css`
        height: 100%;
      `}
`;
export type PageAnimationWrapperProps = {
  children: React.ReactNode;
  animationProps?: AnimationProps;
  style?: React.CSSProperties;
  /** Overrides animation settings to hide the animation, used when we don't want to animate an element on every page, i.e. the nav */
  hideAnimation?: boolean;
  // override the selected animation
  animationNameOverride?: PageAnimationTypes;
  index?: number;
  showOnPageLoad?: boolean;
  offsetPadding?: boolean; // fix bug where ascenders/descenders are getting cut off due to parent element's <1 line height
  fullWidth?: boolean;
  fullHeight?: boolean;
  hideDuringDelayValue?: number;
  intersectionObserverThreshold?: number;
};

export const PageAnimationWrapper = (props: PageAnimationWrapperProps) => {
  const {
    children,
    animationProps,
    style,
    hideAnimation,
    index,
    animationNameOverride,
    showOnPageLoad,
    offsetPadding,
    fullWidth,
    fullHeight,
    hideDuringDelayValue,
    intersectionObserverThreshold,
  } = props;

  const {
    pageAnimation,
    showAnimationsInEditWebsite,
    speed,
    animationsEnabledForAccount,
    showDebug,
    startScaleAt,
    pageAnimationId,
    translateYPercent,
    translateYPercentHero,
    translateYPercentNav,
    easingFunction,
    translateXPercent,
    speedHero,
    speedNav,
    startScaleAtHero,
    startScaleAtNav,
    easingFunctionHero,
    easingFunctionNav,
    translateXPercentHero,
    translateXPercentNav,
    isEditWebsite,
  } = useAnimationContext();

  const debugScale = startScaleAt ? startScaleAt / 100 : 0.8;

  const { isHero, isNav } = animationProps || {};

  const getDebugPropsToUse = () => {
    if (isHero) {
      return {
        ...animationProps,
        speed: speedHero || speed,
        startScaleAt: startScaleAtHero ? startScaleAtHero / 100 : debugScale,
        translateYPercent,
        translateYPercentHero,
        translateYPercentNav,
        easeFunction: easingFunctionHero || easingFunction,
        translateXPercent: translateXPercentHero || translateXPercent,
      };
    }

    if (isNav) {
      return {
        ...animationProps,
        speed: speedNav || speed,
        startScaleAt: startScaleAtNav ? startScaleAtNav / 100 : debugScale,
        translateYPercent,
        translateYPercentHero,
        translateYPercentNav,
        easeFunction: easingFunctionNav || easingFunction,
        translateXPercent: translateXPercentNav || translateXPercent,
      };
    }

    return {
      ...animationProps,
      speed,
      startScaleAt: debugScale,
      translateYPercent,
      translateYPercentHero,
      translateYPercentNav,
      easeFunction: easingFunction,
      translateXPercent,
    };
  };
  const animationPropsToUse = showDebug ? getDebugPropsToUse() : animationProps;

  const showAnimations = animationsEnabledForAccount || showAnimationsInEditWebsite;

  if (showAnimations && !hideAnimation && !!pageAnimation) {
    return (
      <StyledDiv offsetPadding={offsetPadding} fullWidth={fullWidth} fullHeight={fullHeight}>
        <PageAnimation
          pageAnimation={animationNameOverride || pageAnimation}
          showAnimations={showAnimations}
          animationProps={animationPropsToUse}
          style={style}
          key={pageAnimationId}
          index={index}
          showOnPageLoad={showOnPageLoad}
          hideDuringDelayValue={hideDuringDelayValue}
          inViewOffset={isEditWebsite ? 122 : 0}
          intersectionObserverThreshold={intersectionObserverThreshold}
        >
          {children}
        </PageAnimation>
      </StyledDiv>
    );
  }

  return <Fragment>{children}</Fragment>;
};
