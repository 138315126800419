import React, { useContext, useRef } from 'react';
import { renderAbsoluteAssets } from 'components/publicWebsiteV2/util/renderHelpers';
import { DeviceContext } from 'contexts/DeviceContext';
import { useContainerDimensions } from '@zola/zola-ui/src/hooks/useContainerDimensions';
import { useWebsiteThemeContext } from 'components/publicWebsiteV2/context';
import { mapBackgroundToInlineStyle } from 'components/publicWebsiteV2/util/mappers';
import { Container } from './ContentContainerComponent.styles';
import InnerContainer from '../InnerContainer';

// Used on multi page layout only
const ContentContainerComponent: React.FC<{
  className?: string;
  addTopPadding?: boolean;
  pageType?: string;
}> = ({ className, children, addTopPadding, pageType }) => {
  const {
    state: { wedding: weddingTheme },
  } = useWebsiteThemeContext();
  const { device } = useContext(DeviceContext);
  const {
    absolute_assets: contentContainerAbsoluteAssets,
    in_flow_assets: contentContainerInFlowAssets,
    background: contentContainerBackgroundAsset,
  } = weddingTheme?.public_theme_v2?.components?.CONTENT_CONTAINER || {};

  const containerRef = useRef(null);
  const { width: containerWidth } = useContainerDimensions(containerRef);

  return (
    <Container
      addTopPadding={addTopPadding}
      style={mapBackgroundToInlineStyle(contentContainerBackgroundAsset)}
      className={className}
      containerWidth={containerWidth}
      device={device}
      inFlowAssets={contentContainerInFlowAssets}
      ref={containerRef}
    >
      {renderAbsoluteAssets({
        a: contentContainerAbsoluteAssets,
        containerWidth,
        device,
        pageType,
      })}
      <InnerContainer>{children}</InnerContainer>
    </Container>
  );
};

export default ContentContainerComponent;
